import React, {useState} from 'react';

import SignIn from './components/SignIn';
import StoreDashboard from './components/StoreDashboard';
import { Context } from '../src/util/context';
import { ToastContainer } from 'react-toastify';
import AdminSignIn from './components/AdminSignIn';
import Admin from './components/Admin';

import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('store')));
  const [loggedIn, setLoggedIn] = useState(!!user);

  const [admin, setAdmin] = useState(JSON.parse(localStorage.getItem('admin')));
  const [adminLoggedIn, setAdminLoggedIn] = useState(!!admin);

  const isAdmin = window.location.pathname === '/admin'

  const logout = () => {
    localStorage.removeItem('store');
    localStorage.removeItem('admin');
    setUser(null);
    setAdmin(null);
    setLoggedIn(false);
    setAdminLoggedIn(false);
  }

  return (
    <Context.Provider value={[loggedIn, setLoggedIn, user, setUser, adminLoggedIn, setAdminLoggedIn ]}>

      {(loggedIn || adminLoggedIn) && 
      <nav>
        <p>
          <span>Dashboard</span>
        </p>
        <p onClick={logout}>Logout</p>
      </nav>}
      <div className='welcome'>
        {!adminLoggedIn && isAdmin && <AdminSignIn />}
        {adminLoggedIn && isAdmin && <Admin />}
        {!isAdmin && !loggedIn && <SignIn />}
        {!isAdmin &&  loggedIn && <StoreDashboard />}
      </div>
      <ToastContainer />
    </Context.Provider>

  );
}

export default App;
