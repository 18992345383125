import React from 'react';
function CustomFilter({ column: { filterValue, filter, setFilter } }) {
  return (
    <div>
      <input
        className="form-control"
        value={filterValue || ''}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      />
    </div>
  );
}

export { CustomFilter };
