import React, {useContext} from "react";

import { Context } from '../../util/context';
import AddPurchase from "./AddPurchase";
import './index.css';
 
export default function SignIn() {
 const [ loggedIn, setLoggedIn, user, setUser ] = useContext(Context);
   console.log('user', user);
  return (
    <>
       {user && 
       <>
        <div className="dashboard-for">Dashboard for {user.store_name}</div> 
        <AddPurchase />
       </>
       }
    </>
  );
}