import React, {useState, useContext} from "react";
import { useForm } from "react-hook-form";
import { supabase } from "../../supabaseClient";
import { Context } from '../../util/context';

import './index.css';
 
export default function SignIn() {
  const [signinError, setSigninError] = useState(false);
  const [loggedIn, setLoggedIn, user, setUser, adminLoggedIn, setAdminLoggedIn] = useContext(Context);

  const { register, formState: { errors, isSubmitting }, handleSubmit } = useForm();
  const onSubmit =  async data => {
    console.log('ddd', data.key);
    const { data: admin, error } = await supabase
        .from('admin')
        .select('*')
        .eq('key', data.key)

    console.log('ttt', admin, error);
    admin.length !== 0 && admin && localStorage.setItem('admin', JSON.stringify(admin[0]));
    error | admin.length === 0 && setSigninError(true);
    admin.length !== 0 && admin && setAdminLoggedIn(true);
  }
   
  return (
    <>
        <form onSubmit={handleSubmit(onSubmit)} className="form">
        <p>Welcome to Admin</p>

        <label>Key</label>
        <input {...register("key", { required: true })}  aria-invalid={errors.key ? "true" : "false"}/>
        {errors.key?.type === 'required' && <span role="alert">Store Key is required</span>}

        <button>
          {isSubmitting ? 'loading ...': 'Submit'}
        </button>
        </form>
        {signinError && <div className="contact-admin">Login Error, Contact Admin</div>}
    </>
  );
}