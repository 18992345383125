import * as React from 'react';
import './style.css';
import { useTable, useFilters } from 'react-table';
import { CustomFilter } from './custom-filter';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { supabase } from "../../supabaseClient";
import { ExportToCsv } from 'export-to-csv';

export default function Admin() {

  const [rpc, setRpc]= React.useState([]);
  const [price, setPrice]= React.useState(0);
  const [paid, setPaid]= React.useState(0);

  React.useEffect(() => {
    getData();
  },[])

  const getData = async() => {
    const { data: rpc } = await supabase.rpc('hello_world4')
    setRpc(rpc);
  }

  let columns = React.useMemo(
    () => [
      {
        Header: 'Store Name',
        accessor: 'store_name',
      },
      {
        Header: 'Company Name',
        accessor: 'company_name',
      },
      {
        Header: 'Purchase Name',
        accessor: 'purchase_name',
      },
      {
        Header: 'price',
        accessor: 'price',
        className: 'price',
      },
      {
        Header: 'paid',
        accessor: 'paid',
        className: 'paid',
      },
      {
        Header: 'Date',
        accessor: 'date',
      },
    ],
    []
  );
    let data = rpc;
  let defaultColumn = React.useMemo(
    () => ({
      Filter: CustomFilter,
      filter: 'text',
    }),
    []
  );
  let {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    useFilters
  );
  console.log('state', state, rows.map((x) => x.values))

  const memoizedResult = React.useCallback(async() => {
    const priceRows =  document.getElementsByClassName('price');
    const paidRows =  document.getElementsByClassName('paid');
    let totalPrice = 0;
    let totalPaid = 0; 
    if(prepareRow.length > 0){
        for (const element of priceRows) {
            console.log(parseInt(element.innerHTML || 0));
            totalPrice = totalPrice + parseInt(element.innerHTML || 0);
        }
        for (const element of paidRows) {
            console.log(parseInt(element.innerHTML || 0));
            totalPaid = totalPaid + parseInt(element.innerHTML || 0);
        }
    }
    setPrice(totalPrice)
    setPaid(totalPaid)
  }, [prepareRow.length]);

  React.useEffect(() => {
    memoizedResult();
  },[rpc, rows, memoizedResult])

  const options = { 
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true, 
    showTitle: true,
    title: 'My Awesome CSV',
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: false,
    headers:  [ 'Store Name', 'Company Name','Purchase Name','price','paid','Date'],
  };

  const downloadCsv = () => {
    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(rows.map((x) => x.values));
  }

  return (
    <>
            <div class="d-flex w-100 justify-content-evenly">
                <div>
                        <p>Total Price : {price} </p>
                        <p>Total Paid : {paid} </p>
                        <p>Total Rows : {rows.length} </p>
                        <button type="button" class="btn btn-primary" onClick={downloadCsv}>Export Results as CSV</button>
                </div>
                <div>Filters</div>
            </div>
                <table className="table w-100" {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        <th class="align-middle"> No. </th>
                        {headerGroup.headers.map((column) => (
                        <th scope="col" {...column.getHeaderProps()}>
                            <span className="d-flex align-items-center">
                            <span>{column.render('Header')}</span>
                            <span className="dropdown">
                                <button
                                className="btn filter-indicator"
                                id="dropdownMenuButton"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                >
                                <FilterAltIcon></FilterAltIcon>
                                </button>
                                <div
                                className="dropdown-menu px-4 py-3"
                                aria-labelledby="dropdownMenuButton"
                                >
                                {column.render('Filter')}
                                </div>
                            </span>
                            </span>
                        </th>
                        ))}
                    </tr>
                    ))}
                </thead>
                { rpc.length > 0 && 
                        <tbody {...getTableBodyProps()}>
                            {rows.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                <td className="pl-3">
                                    #{i+1}
                                </td>
                                {row.cells.map((cell) => {
                                    return <td {...cell.getCellProps({
                                        className: cell.column.className
                                    })}>{cell.render('Cell')}</td>;
                                })}
                                </tr>
                            );
                            })}
                        </tbody>
                }
                {rpc.length === 0 && 
                   <div class="spinner-border" role="status">
                    <span class="sr-only"></span>
                   </div>
                }
                </table>
                </>
  );
}
