import React, {useState, useContext} from "react";
import { useForm } from "react-hook-form";
import { supabase } from "../../supabaseClient";
import { Context } from '../../util/context';

import './index.css';
 
export default function SignIn() {
  const [signinError, setSigninError] = useState(false);
  const [loggedIn, setLoggedIn, user, setUser] = useContext(Context);

  const { register, formState: { errors, isSubmitting }, handleSubmit } = useForm();
  const onSubmit =  async data => {
    console.log('ddd', data);
    const { data: store, error } = await supabase
        .from('stores')
        .select('*')
        .eq('name', data.name)
        .eq('key', data.key)
    console.log('ttt', store, error);
    store.length !== 0 && store && localStorage.setItem('store', JSON.stringify(store[0]));
    error | store.length === 0 && setSigninError(true);
    store.length !== 0 && store && setLoggedIn(true);
    store.length !== 0 && store && setUser(store[0]);
  }
   
  return (
    <>
        <form onSubmit={handleSubmit(onSubmit)} className="form">
        <p>Welcome to Hadia Stores</p>
        <label>Store Name</label>
        <input {...register("name", { required: true })} aria-invalid={errors.name ? "true" : "false"} />
        {errors.name?.type === 'required' && <span role="alert">Store Name is required</span>}

        <label>Store Key</label>
        <input type="password" {...register("key", { required: true })}  aria-invalid={errors.key ? "true" : "false"}/>
        {errors.key?.type === 'required' && <span role="alert">Store Key is required</span>}

        <button>
          {isSubmitting ? 'loading ...': 'Submit'}
        </button>
        </form>
        {signinError && <div className="contact-admin">Login Error, Contact Admin</div>}
    </>
  );
}