import React, {useState, useContext, useEffect} from "react";
import { useForm } from "react-hook-form";
import { supabase } from "../../../supabaseClient";
import { Context } from '../../../util/context';
import { toast } from 'react-toastify';

import './index.css';
 
export default function AddPurchase() {
  const [signinError, setSigninError] = useState(false);
  const [companies, setCompanies] = useState(false);
  const [loggedIn, setLoggedIn, user, setUser] = useContext(Context);

  useEffect(() => {
    getCompanies();
  },[])

  const getCompanies = async() => {
    const { data, error } = await supabase
    .from('companies')
    .select('*')
    data && setCompanies(data);
    console.log('compan', data);
    const { data: rpc, error1 } = await supabase.rpc('hello_world4')
    console.log('rpc', rpc, error1);
  }

  const { register, formState: { errors, isSubmitting }, handleSubmit, reset } = useForm();

  const onSubmit =  async data => {
    console.log('ddd', data);
    const { data: purchase, error } = await supabase
        .from('purchases')
        .insert({ name: data.name, price: data.price, store_id: user.id, company_id: data.company, paid: data.paid })
        .select()
    purchase && reset();
    purchase && toast("A new Purchase Added !")
    console.log('Purchase', purchase);

  }
   
  return (
    <>
        <form onSubmit={handleSubmit(onSubmit)} className="form">
        <div>Add a Purchase</div>
  
        <label>Company</label>
        <select {...register("company", { required: true })} aria-invalid={errors.company ? "true" : "false"}>
          {companies && companies.map((company) =>
             <option value={company.id}>{company.name}</option>
          )}
        </select>
        {errors.company?.type === 'required' && <span role="alert">Company  is required</span>}

        <label>Name</label>
        <input {...register("name")} aria-invalid={errors.name ? "true" : "false"} />
        {errors.name?.type === 'required' && <span role="alert">Name is required</span>}

        <label>Price</label>
        <input   defaultValue={0} {...register("price", {valueAsNumber: true, validate: (value) => value > -1 })}  aria-invalid={errors.price ? "true" : "false"}/>
        {errors.price?.type === 'required' && <span role="alert">Price is required</span>}
        {errors.price?.type === 'validate' && <span role="alert">Price should be a Number</span>}


        <label>Paid</label>
        <input defaultValue={0} {...register("paid", {valueAsNumber: true, validate: (value) => value > -1 })}  aria-invalid={errors.paid ? "true" : "false"}/>
        {errors.paid?.type === 'required' && <span role="alert">Paid is required</span>}
        {errors.paid?.type === 'validate' && <span role="alert">Paid should be a Number</span>}


        <button>
              {isSubmitting ? 'Adding ...' : 'Add'}
        </button>
        </form>
        {signinError && <div>Add Error, Contact Admin</div>}
    </>
  );
}